import styled from "styled-components";

export const Title = styled.h1`
    //text-align: center;  
    font-size: 7rem;
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
        font-size: 4.5rem;
    }
`;

export const SubTitle = styled.h3`
    //text-align: center;
    font-size: 3rem;
    padding: 0;
    margin: 0;
`;

export const Body = styled.p`
    //text-align: center;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

export const Small = styled.p`
    text-align: center;
    font-size: 1rem;
    padding: 0;
    margin: 0;
`;