import React from 'react';
import {Title} from "../../Components/Text"
// import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
// import styled from "styled-components";
// import {useNavigate} from "react-router-dom";

// const LoginButtonWrapper = styled.div`
//   width: 75px;
//   height: 15px;
//   padding: 20px;
//   background: #B8FF00;
//   color: black;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   transition: transform 0.2s;
//   `

function Authenticate() {
    // const navigate = useNavigate();
    // const handleLoginClick = async () => {
    //     const auth = getAuth();
    //     const provider = new OAuthProvider('apple.com');
    //     signInWithPopup(auth, provider)
    //       .then((result) => {
    //           navigate('/dash');
    //       })
    //       .catch((error) => {
    //         const errorCode = error.code;
    //         const errorMessage = error.message;
    //         console.log(errorCode)
    //         console.log(errorMessage)
    //         // const email = error.customData.email;
    //         const credential = OAuthProvider.credentialFromError(error);
    //         console.log(credential)
    //       });
    // };
    return (
    <div>
        <Title>Login</Title>
      {/*  <LoginButtonWrapper onClick={alert("not implemented yet")}>*/}
      {/*  <h2>Login</h2>*/}
      {/*</LoginButtonWrapper>*/}
    </div>
    );
}

export default Authenticate;
