import React from 'react';
import {Title, Body, SubTitle} from "../../Components/Text"
import styled from "styled-components";

const PrivacyWrapper = styled.div`
    width: 100%;
    text-align: left;
    background: black;
    height: auto;
    //padding-top: 10px;
    overflow: auto; /* Ensure overflow is handled */
`;


function PrivacyPolicy() {
  return (
    <PrivacyWrapper>
        <Title style={{color: "#B8FE01"}}>Privacy Policy</Title>
            <Body>This privacy policy applies to the Overload app (hereby referred to as "Application") for mobile devices that was created by Overload Workout (hereby referred to as "Service Provider") as a Freemium service. This service is intended for use "AS IS".</Body>
            <SubTitle style={{color: "#B8FE01"}}>Information Collection and Use</SubTitle>
        <Body>
            The Application collects anonymous, non-identifiable data when you download and use it for the purpose of
            crash logs. This data may include (not limited to) information such as:
            <ul>
                <li>Your device's Internet Protocol address (e.g. IP address)</li>
                <li>The pages of the Application that you visit, the time and date of your visit, the time spent on
                    those views
                </li>
                <li>The time spent on the Application</li>
                <li>The operating system you use on your mobile device</li>
            </ul>
            The user may choose to provide additional data. The Application does not gather precise location information
            of your mobile device unless it is explicitly granted for specific feature functionality, such as tracking
            workout locations. It never collects location data for other purposes, such as analytical data.
            <br/>
            <br/>
            The Service Provider may use your email that you provided to contact you from time to time to provide you
            with important information, required notices and marketing promotions.
            <br/>
            <br/>
            For a better experience, while using the Application, the Service Provider may require you to provide us
            with certain personally identifiable information, including but not limited to your email, and a userid. The
            information that the Service Provider request will be retained by them and used as described in this privacy
            policy.
        </Body>
        <SubTitle style={{color: "#B8FE01"}}>Third Party Access</SubTitle>
        <Body>
            Only aggregated, anonymized data is periodically transmitted to external services to aid the Service
            Provider in improving the Application and their service. The Service Provider may share your information
            with third parties in the ways that are described in this privacy statement.
            <br/>
            <br/>
            Please note that the Application utilizes third-party services that have their own Privacy Policy about
            handling data. Below are the links to the Privacy Policy of the third-party service providers used by the
            Application:
            <ul>
                <li><a href={"https://firebase.google.com/support/privacy"} style={{color: "white", textDecoration: "underline"}}>Google Analytics for Firebase</a></li>
                <li><a href={"https://firebase.google.com/support/privacy/"} style={{color: "white", textDecoration: "underline"}}>Firebase Crashlytics</a></li>
            </ul>
            The Service Provider may disclose User Provided and Automatically Collected Information:
            <ul>
                <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
                <li>when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
                <li>with their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
            </ul>
        </Body>
        <SubTitle style={{color: "#B8FE01"}}>Opt-Out Rights</SubTitle>
        <Body>
            You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
        </Body>
        <SubTitle style={{color: "#B8FE01"}}>Data retention Policy</SubTitle>
        <Body>
            The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please start account deletion within the application or contact them at support@overloadworkout.com and they will respond in a reasonable time.
        </Body>
        <SubTitle style={{color: "#B8FE01"}}>Children</SubTitle>
        <Body>
            The Service Provider does not use the Application to knowingly solicit data from or market to children under
            the age of 13.
            <br/>
            <br/>
            The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect
            personally identifiable information from children under 13 years of age. In the case the Service Provider
            discover that a child under 13 has provided personal information, the Service Provider will immediately
            delete this from their servers. If you are a parent or guardian and you are aware that your child has
            provided us with personal information, please contact the Service Provider (support@overloadworkout.com) so
            that they will be able to take the necessary actions.
        </Body>
        <SubTitle style={{color: "#B8FE01"}}>Changes</SubTitle>
        <Body>
            This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
            <br/>
            <br/>
            This privacy policy is effective as of 20-07-2024
        </Body>
        <SubTitle style={{color: "#B8FE01"}}>Your Consent</SubTitle>
        <Body>
            By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
        </Body>
        <SubTitle  style={{color: "#B8FE01"}}>Contact Us</SubTitle>
        <Body>
            If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at support@overloadworkout.com.
        </Body>
    </PrivacyWrapper>
  );
}

export default PrivacyPolicy;