import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios'; // Import Axios
import {Title, Body, Small, SubTitle} from '../../Components/Text';
import { TextField } from "../../Components/TextField";
import { GreenButton } from "../../Components/Buttons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  background: black;
  gap: 50px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 50px;
  }
`;

const TextContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
  gap: 15px;
  background: black;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HeroContainer = styled.div`
  justify-content: center;
  align-content: center;
  background: black;
`;

const HeroImage = styled.img`
  width: auto; 
  height: 700px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

function Waitlist() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
    setIsValidEmail(true);
  };

  const handleButtonClick = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(email)) {
      try {
        const response = await axios.put('https://marketing.overloadworkout.com/marketing/add', { email });
        console.log('Response:', response.data);
        alert("Thank you! We will be in touch if you are chosen for the beta.")
      } catch (error) {
        console.error('Error adding contact:', error);
        alert("An error occurred, please try again.")
      }
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <Container>
      <TextContent>
        <div style={{gap: "0px"}}>
            <Title>Overload</Title>
            <SubTitle style={{color: "#B8FE01"}}>Stronger. Every. Session.</SubTitle>
        </div>
        <Body>A new way to track your gym workouts with automatic progressive overload and in-depth metrics to keep you motivated</Body>
        <FormContainer>
          <TextField
            placeholder="email"
            value={email}
            onChange={handleInputChange}
            isValid={isValidEmail}
          />
          <GreenButton onClick={handleButtonClick}>Join beta waitlist</GreenButton>
        </FormContainer>
        <Small>Your email will only be used to send an invite to the beta if chosen.</Small>
      </TextContent>
      <HeroContainer>
        <HeroImage src={'/hero_1_final.png'} alt="Hero Image" />
      </HeroContainer>
    </Container>
  );
}

export default Waitlist;
