import WebFont from 'webfontloader';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import NavBar from "./Components/NavBar";
import Waitlist from "./Pages/Waitlist";
import Authenticate from "./Pages/Authenticate";
import Dash from "./Pages/Dash";
import TermsConditions from "./Pages/TermsConditions";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  font-family: "Bebas Neue",serif;
  background: black;
`;


function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Bebas Neue']
      }
    });
   }, []);

  return (
    <BrowserRouter>
        <NavBar/>
        <AppContainer>
            <Routes>
                <Route path="/" element={<Waitlist />} />
                <Route path="/reset" element={<Waitlist />} />
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/terms-and-conditions" element={<TermsConditions/>}/>
                <Route path={"/login"} element={<Authenticate/>}/>
                <Route path="/dash" element={<Dash/>}/>
            </Routes>
        </AppContainer>
    </BrowserRouter>
  );
}

export default App;
