import styled from "styled-components";


export const TextField = styled.input`
  width: 400px;
  height: 25px;
  padding: 1rem;
  font-size: 2rem;
  font-family: inherit;
  background-color: #1C1C1D;
  color: #FFF;
  text-align: left;
  border: ${({ isValid }) => (isValid ? 'none' : '0.1px solid red')};
  outline: ${({ isValid }) => (isValid ? 'none' : '0.1px solid red')};
  
    @media (max-width: 768px) {
        width: 300px;
    }
    
    
  &::placeholder {
    color: #444;
  }
`;