import React from 'react';
// import {onAuthStateChanged} from "firebase/auth";
// import {auth} from "../../Firebase";
// import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {SubTitle, Title} from "../../Components/Text";

const DashContainer = styled.div`
    width: 100%;
    height: 1vh;
    display: flex;
    flex-direction: column;
`

const TopContainer = styled.div`
    width: 100%;
    height: 1vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
`


function Dash() {

    // const navigate = useNavigate();
    const date = new Date();
    // const [isLoading, setIsLoading] = useState(true);
    // const [user, setUser] = useState(null);

    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (user) => {
    //       setUser(user);
    //       if(user == null){
    //           navigate("/login");
    //       }
    //       setIsLoading(false);
    //     });
    //     return () => unsubscribe();
    // }, []);

 return (
     <DashContainer>
         <TopContainer>
             <SubTitle style={{color: "#B8FE01"}}>{formatDate(date)}</SubTitle>
             <Title>Dashboard</Title>
         </TopContainer>
     </DashContainer>
 );
}

const formatDate = (date) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${dayOfWeek}, ${dayOfMonth}${ordinalSuffix(dayOfMonth)} ${month} ${year}`;
};


export default Dash;
