import React from 'react';
import styled from "styled-components";
// import {useNavigate} from "react-router-dom";

const NavBarWrapper = styled.div`
  height: 10vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  padding-right: 20px;
  font-family: "Bebas Neue";
`;

// const LoginButtonWrapper = styled.div`
//   width: 75px;
//   height: 15px;
//   padding: 20px;
//   background: #B8FF00;
//   color: black;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   transition: transform 0.2s;
//
//   &:hover {
//     transform: scale(1.1);
//   }
// `;

function NavBar() {

    // const navigate = useNavigate();
    // const handleLoginClick = () => {
    //     navigate('/login')
    // };

    return (
    <NavBarWrapper>
        {/*<LoginButtonWrapper onClick={handleLoginClick}>*/}
        {/*    <h2>Login</h2>*/}
        {/*</LoginButtonWrapper>*/}
    </NavBarWrapper>
    );
}

export default NavBar;
